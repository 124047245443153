@import "~bootstrap/scss/bootstrap";

.App-header {
  margin-top: 25px;
  text-align: center;
  margin-bottom: 25px;
}

.content {
  margin-bottom: 25px;
  p {
    font-size: 18px;
  }

  h1 {
    text-align: center;
  }
}

footer {
  color: white;
  background-color: black;
  padding: 10px;
}
